import classNames from 'classnames';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { scroller } from 'react-scroll';
import Animation from 'react-reveal/Slide';
import { LinkContainer } from "react-router-bootstrap";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Home.scss';
import logo from '../images/ChapmanLabsVerticalDark-FixedColors-Official-375x250.png';

const serviceList = [
    { title: "Cloud Computing", link: "/services/cloud", icon: "cloud", desc: "Chapman Labs has extensive experience developing and deploying software solutions for the cloud. Whether you are starting from scratch or migrating legacy applications, we can assist." },
    { title: "Software Development", link: "/services/development", icon: "code", desc: "Whether you simply require architectural guidance or desire a custom turnkey solution, Chapman Labs can help you realize your vision." },
    { title: "Embedded Solutions", link: "/services/embedded", icon: "router", desc: "Bringing up hardware doesn't have to be hard. From full featured IoT solutions through simple microcontroller projects, Chapman Labs has the broad expertise necessary to meet your goals." },
    { title: "DevOps", link: "/services/devops", icon: "show_chart", desc: "Producing a successful software product relies on a repeatable testing and delivery process as well as the ability to proactively measure and monitor your solution post deployment. Chapman Labs can position your software to succeed, provide monitoring solutions, and advice on best practices." },
    { title: "Advanced Debugging", link: "/services/development#debugging", icon: "bug_report", desc: "In an ideal world, software and hardware would always perform as designed. But when things don't go as planned, Chapman Labs is ready to advise, mitigate the problems, and improve the quality of your product." },
];

const Home = () => {
    return (
        <>
            <Container fluid="true" className="home-hero d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column w-75 align-items-center text-center">
                    <h2><span className="home-title-1">Innovation. Development.</span></h2>
                    <h2><span className="home-title-2"><i>Achievement.</i></span></h2>
                </div>
            </Container>
            <Container className="col-sm d-flex align-items-center justify-content-center home-logo">
                <img src={logo} ></img>
            </Container>
            <Container className="home-who-we-are-panel">
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <h2 className="home-title-3">Who We Are</h2>
                        <div className="title-separator title-separator-center"></div>
                        <p className="home-company-desc">Chapman Labs is a software development and technology consulting company that provides a wide range of services and solutions to help you innovate, develop, and ultimately achieve your goals.  No matter what your business needs, Chapman Labs can help you succeed. </p>
                    </Col>
                </Row>
            </Container>
            <section className="grey-row home-what-we-do-panel">
                <Container>
                    <Row className="justify-content-center">
                        <Col className="col-sm-8 text-center">
                            <div>
                                <h4 className="home-what-we-do-subtitle">Services</h4>
                                <h2 className="home-title-3">What We Do</h2>
                            </div>
                            <div className="title-separator title-separator-center"></div>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section className="grey-row">
                <Container>
                    <Row className="justify-content-center home-services-panel">
                        {serviceList.map((service, key) =>
                            <Col key={key} className="col-md-4">
                                <Animation bottom>
                                    <div className="home-service">
                                        <div className="home-service-header">
                                            <i className="material-icons-outlined home-service-icon">{service.icon}</i>
                                            <h5 className="home-service-title">{service.title}</h5>
                                        </div>
                                        <div className="home-service-body">
                                            <p className="home-service-description">{service.desc}</p>
                                            <HashLink scroll={el => scroller.scrollTo(el.id, {duration: 1500, delay: 100, smooth: true})} to={service.link}>
                                                <Button variant="outline-secondary">Read more</Button>
                                            </HashLink>
                                        </div>
                                    </div>
                                </Animation>
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Home;