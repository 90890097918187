import React, { Component } from 'react';
import { animateScroll } from 'react-scroll';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './images/logo.png';
import './App.scss';

import { LinkContainer } from "react-router-bootstrap";
import { Link, Route, Switch } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import NavHashDropdownItem from './components/NavHashDropdownItem';
import NavDropdownWrapper from './components/NavDropdownWrapper';
import { scroller } from 'react-scroll';

import ContactWidget from './components/ContactWidget';

import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Service from './pages/Service';
import Container from 'react-bootstrap/Container';

const cloudServices = {
  title: "Cloud Computing",
  callout: "Make the cloud work for you.",
  bgImage: require("./images/cloud-hero.jpg"),
  bgAlpha: 0.44,
  services: [
    { title: "Cloud Solution Design", img: require('./images/greyclouds.png'), desc: "Utilizing cloud services has become increasingly critical for today’s companies, eliminating the need to maintain on-premise infrastructure. As the cloud continuously evolves, it’s critically important to select a strategy that is future proof. By working together to understand what your business looks like today and what your goals are for tomorrow, we can build an intelligent cloud solution that works best for your business, while keeping your needs and budget in mind. From virtual machines to microservice containers to serverless functions, the cloud offers a variety of tools for solving your problems. With our experience working with the various cloud providers, we can help you make the best choice for your business."},
    { title: "Microservice Design", img: require('./images/greygears.png'), desc: "While microservices provide a strong pattern for isolating dependencies and fine-grained scalability control, they also introduce new complexities as the number of services multiply. Log aggregation, monitoring, and metric gathering become critical tools for ensuring ongoing success. By prescribing a path embracing the industry’s best practices, we can ensure project success. Whether you are designing a new solution from scratch or are investigating migrating an existing application, we can help you choose the best path forward."},
    { title: "Docker", img: require('./images/docker.png'), desc: "Docker has become a universal packaging solution for delivering containerized applications to the cloud. Containerizing applications helps to eliminate many of the problems that occur when differences exist between the software development environment and the production deployment environment. A deep understanding of Docker and how it relates to the various cloud orchestration environments is critical to your success. We bring years of experience in designing and deploying containerized applications."},
    { title: "Kubernetes", img: require('./images/kubernetes.png'), desc: "Kubernetes provides an open source orchestration platform for deploying and scaling containerized applications. As its’ popularity soars, it has quickly become a cloud-agnostic standardized solution for deploying containerized applications. The breadth of capabilities the platform offers can make it challenging to determine where to start and how to best structure your application deployments. With our extensive knowledge and utilization of Kubernetes, we can guide you to achieve the ideal solution for your product."},
  ]
};

const softwareServices = {
  title: "Software Development",
  callout: "Customizable for your needs.",
  bgImage: require("./images/dev-hero.jpg"),
  bgAlpha: 0.78,
  services: [
    { title: "Custom Development", img: require('./images/GreyLaptopWithCode.png'), desc: "Custom software can provide you with a solution that is tailored to your specific needs based on your distinct infrastructure, brand elements, and operational requirements. Chapman Labs has years of experience developing custom applications for numerous platforms using a variety of frameworks in a wide range of programming languages. From desktop applications, backend processing workflows, through online interactive portals, we can provide a variety of services for your business needs. Whether you require small modifications to an existing business application or are looking to build a fully customized solution from the ground up, we are well equipped to turn your vision into reality."},
    { title: "Architecture", img: require('./images/GreyArchitecture.png'), desc: "Creating reliable, extensible software starts with a solid plan. An intelligent approach to architecture not only depends on keeping up with the current industry best practices, but also relies on having a breadth of actual hands-on experience understanding what works and what doesn’t. Leveraging decades of experience, Chapman Labs can provide you with a comprehensive approach to building a technology solution customized for the specific needs of your business."},
    { title: "Advanced Debugging", img: require('./images/GreyBugMagnifyingGlass.png'), desc: "Everyone eventually encounters what feels like an impossible to solve software or hardware issue. A deficient, defective, or altogether incorrect solution can often lead to considerable amounts of wasted time and money for your company and your customers. When time is of the essence, having a qualified partner experienced with solving complex issues can make a critical difference for your business. Chapman Labs has a deep low-level understanding of both software and hardware systems as well as expert knowledge of debugging, disassembly, and analysis tools. With these capabilities, Chapman Labs can help you pinpoint your issues, recover your project, and lead you to a timely resolution.", id: "debugging"},
  ]
};

const embeddedServices = {
  title: "Embedded Software",
  callout: "Comprehensive solutions for any device.",
  bgImage: require("./images/embedded-hero.jpg"),
  bgAlpha: 0.51,
  services: [
    { title: "Internet of Things (IoT)", img: require('./images/GreyIOTCloud.png'), desc: "Building internet connected devices requires a mastery of multiple disciplines. A single device will often require a combination of microcontroller programming, custom bootloader / operating system work, network communications logic, backend services for handling device data, a secure update mechanism, as well as a management portal. Chapman Labs gives you an experienced partner to overcome these challenges and bring your IoT device from concept to reality."},
    { title: "Custom Linux Distribution Development", img: require('./images/yocto.png'), desc: "An ideal IoT device runs a minimal fit-for-purpose operating system image, allowing for both a decreased security footprint as well as a reduction in resource allocation. The Yocto Project provides a flexible set of tools that enables the creation of custom Linux-based images for embedded devices. Chapman Lab’s background utilizing the Yocto Project to produce secure, reliable, compact images, provides the perfect foundation for an IoT device.", disclaimer: "Yocto Project and all related marks and logos are trademarks of The Linux Foundation. This website is not, in any way, endorsed by the Yocto Project or The Linux Foundation."},
    { title: "Android Customization (AOSP)", img: require('./images/GreyAndroid.png'), desc: "The Android Open Source Project (AOSP) provides an excellent starting point for the creation of many custom devices. Selecting Android for your operating system increases developer productivity by providing a rich SDK and powerful development tools while also enabling applications to be highly portable across hardware platforms. Android-enabling your hardware platform requires a deep understanding of the numerous components and framework modules that constitute the AOSP. Chapman Lab’s proficiency in customizing and extending the AOSP provides a low-resistance path to building a powerful, stable platform for your custom device.", disclaimer: "The Android robot is reproduced or modified from work created and shared by Google and used according to terms described in the Creative Commons 3.0 Attribution License."},
    { title: "Microcontroller Programming", img: require('./images/GreyMicrocontroller.png'), desc: "Modern microcontrollers cover a wide gamut of power and capabilities. An intimate understanding of a microcontroller’s architecture and integrated peripherals coupled with a solid foundation of embedded software development best practices is required to produce a reliable, robust solution. With experience across a variety of microcontroller solutions from Microchip PIC/tinyAVR/megaAVR, 8051 cores and more, Chapman Labs is the knowledgeable partner you need."},
    { title: "Linux Kernel Development", img: require('./images/GreyPenguin.png'), desc: "The development of Linux-based embedded solutions requires specific hardware support from the Linux kernel. In the best scenarios this is as simple as properly setting the kernel configuration options / device tree to match your platform. In more complex scenarios you may need to make code changes to the kernel to fully support your platform. Chapman Labs provides a broad range of custom kernel development capabilities to ensure your hardware has the support and stability it requires."},
  ]
};

const devOpsServices = {
  title: "DevOps",
  callout: "Optimize your business.",
  bgImage: require("./images/devops-hero.jpg"),
  bgAlpha: 0.51,
  services: [
    { title: "Continuous Integration / Delivery (CI/CD)", img: require('./images/GreyDevOps.png'), desc: "An effective continuous integration and delivery pipeline can dramatically increase the quality and speed of your development process. With the day-to-day demands of software development, it’s often challenging to take the time away from your core business concerns to focus on making the process better. As the software development landscape grows more diverse, constructing a CI/CD pipeline that is prepared to handle today’s challenges while looking toward tomorrow’s becomes increasingly challenging. Chapman Labs has a rich background developing CI/CD solutions for diverse development, testing, and deployment environments. Leveraging tools such as Jenkins and Drone CI, we can build a process that works for you."},
    { title: "Monitoring / Alerting", img: require('./images/GreyCharts.png'), desc: "Businesses should use data to drive their decision making. Metric gathering and log aggregation/processing are basic elements that provide the foundation for collecting the information you need to correctly assess how your technology supports your business. With the proper information and analysis processes you can correct issues before they impact your business. Chapman Labs has constructed monitoring and alerting solutions using a variety of tools such as Grafana, Graphite, InfluxDB, Riemann, Alerta, and Google Stackdriver. We can assist you with all aspects of building a monitoring and alerting solution for your business, from deciding what to gather, how to gather it, and how to best leverage that data."},
  ]
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      footerParallax: false,
      footerHeight: 0,
      arrowUpVisible: false,
    }
  }

  handleResize = () => {
    const footerParallax = document.documentElement.clientWidth >= 992;
    this.setState({ 
      footerParallax: footerParallax,
      footerHeight: this.footer.clientHeight 
    });
  }

  handleScroll = () => {
    const scrollPos = document.body.scrollTop || document.documentElement.scrollTop;
    this.setState({ arrowUpVisible: (scrollPos > 100)});
  }

  componentDidMount() {
    this.handleResize();

    window.addEventListener("resize", this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  isActive = (match, location) => {
    console.log("Match: " + match + " location:" + location)
  }

  render() {
    const footerPos = this.state.footerParallax ? "position-fixed" : "position-static";
    const footerMarginOffset = this.state.footerParallax ? this.state.footerHeight : 0;
    return (
      <div className="App">
        <header className="app-header">
          <Navbar className="justify-content-between" expand="lg" variant="dark" collapseOnSelect="true">
            <Navbar.Brand as="span" href="#home" className="flex-grow-1">
              <Link to="/">
                <img
                  height="70"
                  alt=""
                  src={logo}
                  className="d-inline-block align-top"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="flex-grow-1">
              <Nav className="justify-content-center">
                <Nav.Item>
                  <LinkContainer exact to="/">
                    <Nav.Link>Home</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <NavDropdownWrapper path="/services" title="Services" id="nav-dropdown">
                  <LinkContainer to="/services/cloud">
                    <NavDropdown.Item>Cloud</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/services/development">
                    <NavDropdown.Item>Development</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/services/embedded">
                    <NavDropdown.Item>Embedded</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/services/devops">
                    <NavDropdown.Item>DevOps</NavDropdown.Item>
                  </LinkContainer>
                  <NavHashDropdownItem to="/services/development#debugging" scroll={el => scroller.scrollTo(el.id, {duration: 1500, delay: 100, smooth: true})}>
                    Advanced Debugging
                  </NavHashDropdownItem>
                </NavDropdownWrapper>
                <Nav.Item>
                  <LinkContainer to="/about">
                    <Nav.Link>About</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                  <LinkContainer to="/contact">
                    <Nav.Link>
                      Contact
                    </Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
            <div className="d-none d-lg-block d-xl-block flex-grow-1"></div>

          </Navbar>
        </header>
        <main style={{ marginBottom: footerMarginOffset + 30 }}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/services/cloud" render={() => <Service {...cloudServices}></Service>} />
            <Route path="/services/development" render={() => <Service {...softwareServices}></Service>} />
            <Route path="/services/embedded" render={() => <Service {...embeddedServices}></Service>} />
            <Route path="/services/devops" render={() => <Service {...devOpsServices}></Service>} />
          </Switch>
          <div className="arrow-up" ref={(e) => this.arrowUp = e} hidden={!this.state.arrowUpVisible} onClick={() => { animateScroll.scrollToTop()}}></div>
        </main>
        <footer className={footerPos} ref={(e) => this.footer = e}>
          <div className="footer-info-area">
            <Container>
              <Row>
                <Col className="col-12 col-md-6 footer-info-area-item">
                  <h4 className="footer-title">ABOUT US</h4>
                  <p className="footer-text">With decades of software and product development experience and extensive industry insight, Chapman Laboratories, LLC is prepared to assist with any stage of your business and software development. From architecture guidance to custom software creation to providing IoT solutions, we are ready and able to move your business forward.</p>
                  <HashLink scroll={el => scroller.scrollTo(el.id, {duration: 1500, delay: 100, smooth: true})} to="/about#top">
                    <Button variant="outline-secondary">Learn more</Button>
                  </HashLink>
                </Col>
                <Col className="col-12 col-md-6 footer-info-area-item">
                  <ContactWidget></ContactWidget>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="footer-copyright text-center">
              Copyright 2019 - Chapman Laboratories, LLC
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
