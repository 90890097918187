import React, { Component, useContext } from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavContext from 'react-bootstrap/NavContext';

const NavDropdownWrapper = (props) => {

        const navContext = useContext(NavContext);

        const { children, ...rest } = props;

        const active = navContext.activeKey ? navContext.activeKey.startsWith(props.path) : false;

        return(
            <NavDropdown active={active} {...rest}>{children}</NavDropdown>
        );
}

export default NavDropdownWrapper;