import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import './About.scss';
import chapman from '../images/Chapman.jpg';

const About = () => {
    return (
        <>
            <Container id="top" fluid="true" className="about-hero d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column w-75 align-items-center text-center">
                    <h1><span className="about-hero-title">About Us</span></h1>
                </div>
            </Container>
            <Container className="about-text d-flex justify-content-center">
                <Row className="text-center col-10">
                    <p>With over 20 years of experience in software and technology, Chapman Laboratories, LLC offers a wide range of consulting services to help businesses realize their visions and achieve their goals. Our software development and technology consulting services help you focus on what your business needs by providing you with our advanced experience in software architecture, development and engineering. Armed with a profound understanding of the fundamental technologies that underlie the cloud, networking, embedded devices, and general computer systems, Chapman Laboratories can align your technology approach with your business needs.</p>
                </Row>
            </Container>
            <Container>
                <Row className="about-separator">
                </Row>
            </Container>
            <Container className="about-team-members">
                <Row>
                    <Col className="col-lg-4">
                        <img src={chapman}></img>
                    </Col>
                    <Col className="col-lg-8">
                        <div className="about-team-members-name">Jon Chapman</div>
                        <div className="about-team-members-title">President</div>
                        <p className="about-team-members-desc">
                            Jon Chapman, Founder and President of Chapman Laboratories, began his career in computing and technology at a young age and has since devoted his life to understanding and mastering skills across all realms of the technology industry. His early dedication to the craft landed him a position at an Internet Service Provider (ISP), where he gained extensive hands-on experience with networking, systems administration, and also developed custom software to simplify and automate day-to-day operations.
                        </p>
                        <p className="about-team-members-desc">
                            Following his time at the ISP, Jon joined an international retail software company specializing in Point of Sale (POS) solutions. Initially brought on in an IT services / product support role, Jon’s position evolved to being responsible for diagnosing and fixing critical bugs in the product, testing and fixing scalability, and leading the effort on custom integration between the company’s POS software and one of the world’s largest retailers.
                        </p>
                        <p className="about-team-members-desc">
                            With an uncanny ability to problem solve and provide leadership, Jon became the Senior Vice President of Software Development for Exceptional Innovation where he acted as chief architect and lead programmer while overseeing the software development and quality assurance groups. Exceptional Innovation provided both software and hardware solutions to the home automation and hospitality markets. During his 14-year tenure at the company, Jon created a wide variety of software and hardware products including a home automation control platform, in-room hospitality entertainment platform, a VoIP touchscreen telephone, multiple set-top boxes, and more. Jon’s culmination of experiences, coupled by his endless pursuit of skill advancement and continuous learning, make him uniquely qualified to offer services across a myriad of technologies at Chapman Laboratories.
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default About;