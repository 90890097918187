import React, { useContext } from 'react';
import SelectableContext from 'react-bootstrap/SelectableContext';
import { NavHashLink } from 'react-router-hash-link';

const NavHashDropdownItem = (props) => {
    const onSelectCtx = useContext(SelectableContext);

    const onSelected = (event) => {
        if (onSelectCtx) {
            onSelectCtx(null, event);
        }
    }

    const { children, ...rest } = props;

    return(
        <NavHashLink className="dropdown-item" {...rest} onClick={onSelected}>{children}</NavHashLink>
    );
}

export default NavHashDropdownItem;