

export const sendMessage = (email, name, subject, body) => {
    return fetch("/api/sendMsg", {
        method: 'POST', // or 'PUT'
        body: JSON.stringify({
            email,
            name,
            subject,
            body,
        }), // data can be `string` or {object}!
        headers: {
            'Content-Type': 'application/json'
        }
    })
}