import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { sendMessage } from '../modules/Messaging';

class ContactWidget extends Component {
    constructor() {
        super();
        this.state = {
            formControls: {
                name: '',
                email: '',
                message: '',
            },
            sending: false,
            sent: false,
            error: false,
        }
    }

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const formControls = {
            ...this.state.formControls
        }

        formControls[name] = value;

        this.setState({
            formControls: formControls
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.sendContactForm(this.state.formControls.email, this.state.formControls.name, "No subject", this.state.formControls.message)
    }

    sendContactForm = (email, name, subject, body) => {
        if(this.state.sending === true) {
            return
        }

        this.setState({
            sending: true,
        })

        sendMessage(email, name, subject, body).then(
            () => {
                this.setState({
                    sent: true,
                    error: false,
                    sending: false,
                })
            }).catch(error => {
                console.error('Error:', error)
                this.setState({
                    error: true,
                    sending: false,
                })
            });
    }

    render() {
        return (
            <>
                <h4 className="footer-title">CONTACT US</h4>
                {!this.state.sent ? (
                    <Form onSubmit={this.onSubmit}>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formGridName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control name="name" onChange={this.changeHandler} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control name="email" type="email" onChange={this.changeHandler} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formGridMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" name="message" rows="2" onChange={this.changeHandler} />
                        </Form.Group>

                        {this.state.error &&
                            <Alert variant="danger">
                                Sorry, we were unable to send your message. Please try again shortly.
                            </Alert>
                        }

                        <Button variant="outline-primary" type="submit">
                            {this.state.sending ? "Sending" : "Send"}
                        </Button>
                    </Form>
                ) : (
                        <Alert variant="primary">
                            Thank you for contacting us! We will be in touch shortly.
                        </Alert>
                    )}
            </>
        );
    }
}

export default ContactWidget;