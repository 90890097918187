import classNames from 'classnames'
import React from 'react';
import Animation from 'react-reveal/Slide';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { HashLink } from 'react-router-hash-link';
import { scroller } from 'react-scroll';
import './Service.scss';

// background image, opacity overlay level, title, callout, service list

const Service = (props) => {

    const largeScreen = document.documentElement.clientWidth >= 992;
    const animationProps =  largeScreen ? "" : {when: true};

    const services = props.services.map((service, i) => {
        let serviceID = service.id ? service.id : "";
        let serviceClassName = "service-service grey-row";
        let serviceInfoClassName = "service-service-info-left";
        let left = true;

        if (i % 2 !== 0) {
            serviceClassName = "service-service white-row";
            
            // we only alternate order on bigger screens
            serviceInfoClassName = "service-service-info-right order-1";
            left = false;
        }

        if (serviceID === "" && i === 0) {
            serviceID = "start";
        }

        if(!largeScreen) {
            serviceInfoClassName = "service-service-info-left order-1";
        }

        return (
            <Animation {...animationProps} key={service.title} left={left} right={!left}>
                <section id={serviceID} className={serviceClassName}>
                    <Container>
                        <Row>
                            <Col className={classNames("col-md-7", serviceInfoClassName)}>
                                <h2 className="service-service-title">{service.title}</h2>
                                <div className="service-service-title-separator"></div>
                                <p className="service-service-desc">{service.desc}</p>
                            </Col>
                            <Col className="col-md-5 d-flex justify-content-center align-items-center service-service-img">
                                <img src={service.img}>
                                </img>
                            </Col>
                        </Row>
                        {service.disclaimer &&
                            (
                              <Row>
                                  <Col className="service-service-disclaimer">
                                    <p>{service.disclaimer}</p>
                                  </Col>
                              </Row>  
                            )}
                    </Container>
                </section>
            </Animation>
        );
    })

    const backgroundImage = `
        linear-gradient(
            rgba(0, 0, 0, ${props.bgAlpha}),
            rgba(0, 0, 0, ${props.bgAlpha})
        ), 
        url(${props.bgImage})`;

    return (
        <>
            <Container fluid="true" style={{ backgroundImage: backgroundImage }} className="fullscreen-hero d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h1 className="service-title">{props.title}</h1>
                    <p className="service-callout">{props.callout}</p>
                    <HashLink scroll={el => scroller.scrollTo(el.id, {duration: 1000, delay: 100, smooth: true})} to="#start">
                        <Button className="service-button">Learn more</Button>
                    </HashLink>
                </div>
            </Container>
            {services}
        </>
    );
}

export default Service;