import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './Contact.scss';

import { sendMessage } from '../modules/Messaging';

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            formControls: {
                name: '',
                email: '',
                subject: '',
                message: '',
            },
            sending: false,
            sent: false,
            error: false,
        }
    }

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const formControls = {
            ...this.state.formControls
        }

        formControls[name] = value;

        this.setState({
            formControls: formControls
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.sendContactForm(this.state.formControls.email, this.state.formControls.name, this.state.formControls.subject, this.state.formControls.message)
    }

    sendContactForm = (email, name, subject, body) => {
        if(this.state.sending === true) {
            return
        }
        
        this.setState({
            sending: true,
        })

        sendMessage(email, name, subject, body).then(
            () => {
                this.setState({
                    sent: true,
                    error: false,
                    sending: false,
                })
            }).catch(error => {
                console.error('Error:', error)
                this.setState({
                    error: true,
                    sending: false,
                })
            });
    }

    render() {
        return (
            <>
                <Container fluid="true" className="contact-hero d-flex align-items-start justify-content-center">
                    <div className="contact-hero-inset d-flex flex-column align-items-center text-center">
                        <h1 className="contact-hero-inset-title">Contact</h1>
                        <p className="contact-hero-inset-text">
                            Please contact us today to schedule a consultation or to obtain more information about any of our services.
                        <br></br>
                            <br></br>
                            Phone: (614) 358-0338
                        <br></br>
                            Email: <a href="mailto:info@chapmanlabs.com">info@chapmanlabs.com</a>
                        </p>
                        {!this.state.sent ? (
                            <Form className="container" onSubmit={this.onSubmit}>
                                <Row className="no-gutters">
                                    <Col className="col-12 col-md-6">
                                        <Form.Control className="contact-form-input form-control-lg" name="name" placeholder="Name" onChange={this.changeHandler} />
                                        <Form.Control className="contact-form-input form-control-lg" name="email" type="email" placeholder="Email" onChange={this.changeHandler} />
                                        <Form.Control className="contact-form-input form-control-lg" name="subject" placeholder="Subject" onChange={this.changeHandler} />
                                    </Col>
                                    <Col className="col-12 col-md-6">
                                        <Form.Control className="contact-form-textarea form-control-lg" name="message" as="textarea" placeholder="Message" onChange={this.changeHandler} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-md-12">
                                        <Button className="contact-form-submit" variant="primary" type="submit">
                                            {this.state.sending ? ("Sending") : ("Send")}
                                        </Button>
                                        {this.state.error &&
                                            <Alert variant="danger">
                                                Sorry, we were unable to send your message. Please try again shortly.
                                            </Alert>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                                <Alert variant="primary">
                                    Thank you for contacting us! We will be in touch shortly.
                                </Alert>
                            )}
                    </div>
                </Container>
            </>
        );
    }
}

export default Contact;